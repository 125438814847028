import useStateHooks from '@/pages/public/bootPage/useStateHooks';
import BootPageChooseALanguage from './components/BootPageChooseALanguage';
import BootPageSelectTheme from './components/BootPageSelectTheme';

export default () => {
  //step-0 选择语言 step-1 选择主题
  const { step, setStep } = useStateHooks();
  return (
    <div>
      {step === 0 && <BootPageChooseALanguage setStep={setStep} />}
      {step === 1 && <BootPageSelectTheme />}
    </div>
  );
};
