import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { MaterialSymbolsFilterBAndWSharp } from '@/assets/icons/comm/MaterialSymbolsFilterBAndWSharp';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import { Button } from '@nextui-org/react';
import { history } from '@umijs/max';
import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';

export default function Mine() {
  const { t } = useTranslation();

  const { theme, setTheme } = useTheme();

  const cardArr4: any = [
    {
      key: 'light',
      label: t('白色主题'),
      url: PageEnum.USER_LANGUAGE,
      onclick: () => {
        setTheme(ThemeEnum.light);
      },
    },
    {
      key: 'dark',
      label: t('黑色主题'),
      onclick: () => {
        setTheme(ThemeEnum.dark);
      },
    },
  ]?.filter((i) => !!i);

  return (
    <div>
      <div className="px-6 pt-8">
        {/*动画*/}
        <div className="flex flex-col items-center justify-center mt-4 mb-10">
          <MaterialSymbolsFilterBAndWSharp
            className="text-primary"
            width="4em"
            height="4em"
          />
          <div className="text-backContrastColor text-xl mt-2">
            {t('选择主题色')}
          </div>
        </div>
        {/*语言列表*/}
        <div>
          {cardArr4.map((item: any) => {
            return (
              <div
                className="flex mt-6 bg-backgroundAuxiliaryColor p-3 rounded-md pl-4 justify-between"
                key={item.key}
                onClick={() => setTheme(item.key)}
              >
                <div className="flex">
                  <span className="ml-2">{item.label}</span>
                </div>

                {theme === item.key && <IconParkSolidCorrect />}
              </div>
            );
          })}
        </div>
      </div>
      <div className="px-6 mt-8">
        <Button
          className="mainColorButton"
          onClick={() => {
            localStorage.removeItem(CacheEnum.APP_IS_GUIDE);
            history.replace(PageEnum.HOME);
          }}
        >
          {t('进入')}
        </Button>
      </div>
    </div>
  );
}
