import React from 'react';
import type { SVGProps } from 'react';

export function MaterialSymbolsFilterBAndWSharp(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="currentColor" d="M21 21H3V3h18zM5 19h7v-8l7 8V5h-7v6z"></path>
    </svg>
  );
}