import {MaterialSymbolsLanguage} from '@/assets/icons/comm/MaterialSymbolsLanguage';
import {Button} from '@nextui-org/react';
import {useTranslation} from 'react-i18next';
import { IconParkSolidCorrect } from "@/assets/icons/comm/IconParkSolidCorrect";
import { useModel } from '@@/exports';

/**
 * 国际化选语言
 * **/
export default ({ setStep }: IProps) => {
  const { setAppLanguage, language, languageList } = useModel('language');


  const { t } = useTranslation();

  return (
    <div id="Language">
      <div className="px-6 pt-8">
        {/*动画*/}
        <div className="flex flex-col items-center justify-center mt-4 mb-10">
          <MaterialSymbolsLanguage
            className="text-primary"
            width="4em"
            height="4em"
          />
          <div className="text-backContrastColor text-xl mt-2">
            {t('选择语言')}
          </div>
        </div>
        {/*语言列表*/}
        <div>
          {languageList.map((item: any) => {
            return (
              <div
                className="flex mt-6 bg-backgroundAuxiliaryColor p-3 rounded-md pl-4 justify-between"
                key={item.code}
                onClick={() => setAppLanguage(item.code)}
              >
                <div className="flex">
                  <img src={item.avatar} alt="" width={20} />

                  <span className="ml-2">{item.name}</span>
                </div>

                {language === item.code && (
                  <IconParkSolidCorrect  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="px-6 mt-8">
        <Button
          className="mainColorButton"
          onClick={() => {
            setStep(1);
          }}
        >
          {t('开始启用')}
        </Button>
      </div>
    </div>
  );
};

interface IProps {
  setStep: (step: number) => void;
}
